<template>
  <dash-page-new
      :title="$store.state.discount.pageData.title || $t('DiscountSystem')"
      :icon="$store.state.discount.pageData.icon || 'mdi-sale'"

  >

    <template #header_action.desktop>
      <v-btn v-if="$store.state.discount.pageData.headerAction"
             @click="$store.state.discount.pageData.headerAction()"
             :color="wsATTENTION"
             class="noCaps"
             dark>
        {{ $store.state.discount.pageData.headerActionText }}
      </v-btn>
    </template>

    <template #header_action.mobile>
      <v-btn v-if="$store.state.discount.pageData.headerAction"
             @click="$store.state.discount.pageData.headerAction()" :color="wsATTENTION" dark>
        <v-icon>
          {{ $store.state.discount.pageData.headerActionIcon || 'mdi-save' }}
        </v-icon>
      </v-btn>
    </template>

    <template #default>
      <router-view></router-view>
    </template>
  </dash-page-new>
</template>

<script>
export default {
  name: "BusinessDiscount",
mounted() {
  this.$store.state.discount.navigation = this.navigationItems
},
beforeDestroy() {
  this.$store.state.discount.pageData = {}
  this.$store.state.discount.filtersData = {}
}
}
</script>

<style scoped>

</style>